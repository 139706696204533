import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LineData } from "common/interfaces/lineTypes";
import AYPALineChart from "common/components/lineChart";
import { formatPriceDataRawAll, getConfigTimestamp } from "common/components/lineChart/utils";
import { ApplicationState } from "store";
import { setPriceImage } from "pages/home/stores/slices/settings-slice";
import { Loading } from "pages/home/mainSection/components/loading";
import { PriceDataRaw, PriceDataRawChart } from "common/interfaces/priceData";
import { PriceListData } from "common/interfaces/priceListData";
import DefaultButton from "common/components/button";
import { fetchRunPriceLookupRaw } from "pages/home/stores/slices/prices-slice";
import { DateTime } from "luxon";
import { HelpHover } from "common/components/helpButton";
import classes from "./../../style.module.scss";


export const PriceRawChartWrapper = () => {
    
    /**CONSTANTS */
    const dispatch = useDispatch();

    /**SELECTORS */
    const settingsReducer = useSelector((store: ApplicationState) => store.settingsReducer);
    const priceList = useSelector((store: ApplicationState) => store.settingsReducer.priceList);
    const priceDataCharts = useSelector((store: ApplicationState) => store.settingsReducer.priceDataCharts);
    const priceDataRawCharts = useSelector((store: ApplicationState) => store.settingsReducer.priceDataRawCharts);
    const isLoadingChart = useSelector((store: ApplicationState) => store.pricesReducer.isLoadingChart);
    const errorMessage = useSelector((store: ApplicationState) => store.pricesReducer.errorMessage);
    const helpPrices = ["Displays the timeseries prices.",
    "Data is downsampled with LTTB algorithm.  It shows price at timestamp, not an average.  It perserves peaks.",
    "Zoom in for more details with the mouse wheel or click and drag the mouse.", 
    "Pan by holding 'ctrl' while clicking and dragging the mouse"]

    /**STATES */
    // const [longLabel, setLongLabel] = useState<boolean | null>(true);
    const [lineData, setLineData] = useState<Array<LineData>>();
    let longLabel: boolean | null = true;
    const [chartConfig, setChartConfig] = useState<any>({});


    const onCompleteChartRender = (chart: any) => {
        // can't download image with multiple charts
        // initial does not exist when zooming or panning.  it is false when hovering over datapoint.
        let initialChart = null;
        if ('initial' in chart) {
            initialChart = chart.initial;
        }
        if (chart?.chart && !settingsReducer.priceChartBase64Image && (initialChart === null || initialChart === true)) {
            dispatch(setPriceImage(chart.chart.toBase64Image()));
        }
    }

    const updateChartLabels = () => {
        if (longLabel == null) {
            longLabel = true;
        } else if (longLabel) {
            longLabel = false;
        } else {
            longLabel = null;
        }
        onCompleteFetchRawPrice({dataObjects: priceDataRawCharts}, longLabel);
    }

    const onCompleteFetchRawPrice = ({dataObjects} : any, longLabel: boolean | null) => {
        let dataArrayAll = Array<PriceDataRawChart>();

        for (const key in dataObjects) {
            const data = dataObjects[key];
            // get name of request for labels
            let matchingRequest: PriceListData | null = null;
            for (const req of priceList) {
                if (req.eventId === key) {
                    matchingRequest = req;
                    break;
                }
            }
            if (!matchingRequest) {
                continue;
            }
            const dataArray: Array<PriceDataRaw> = [];
            data.data.forEach((point: any) => {
                dataArray.push({
                    "timestamp": DateTime.fromISO(point["timestamp"]),
                    "price": point["price"]
                })
            })
            let label = matchingRequest.name;
            if (longLabel !== null && !longLabel) {
                let lastHyphenIndex = matchingRequest.name.lastIndexOf(" | ");
                if (lastHyphenIndex !== -1) {
                    label = matchingRequest.name.substring(lastHyphenIndex+3);
                }
            }
            const priceChartData: PriceDataRawChart = {
                data: dataArray,
                name: label,
                id: matchingRequest.eventId
            }
            dataArrayAll.push(priceChartData);
        }

        const { lineData } = formatPriceDataRawAll(dataArrayAll);
        setLineData(lineData);
        setChartConfig(getConfigTimestamp('Price [$]', `Timestamp`, longLabel === null ? false : true));
    }

    useEffect(() => {
        // this will be filled in from the price lookup. only trigger lookup if no data for all in list
        if (Object.keys(priceDataCharts).length !== 0 && Object.keys(priceDataRawCharts).length !== priceList.length ) {
            dispatch(fetchRunPriceLookupRaw())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceDataCharts]);

    useEffect(() => {
        // this will trigger after raw data has been formatted to chart and for all in list
        // loop adds to charts, possible bug if this triggers before all charts are added?
        const rawCharts = Object.keys(priceDataRawCharts).length;
        if (rawCharts !== 0 && rawCharts == priceList.length) {
            const config = getConfigTimestamp('Price [$]', `Timestamp`, longLabel === null ? false : true);
            setChartConfig(config);
            onCompleteFetchRawPrice({dataObjects: priceDataRawCharts}, longLabel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceDataRawCharts, priceList]);

    return (
        <Loading isLoading={isLoadingChart} errorMessage={errorMessage}>
            <>
            <div className="tabContainer" >
                <div className={classes.sectionTitleWithHelpCenter}>
                    <div className={classes.sectionTitleNoMargin}><b>Price Timeseries</b></div>
                    <HelpHover messageList={helpPrices} largeMarg={false}></HelpHover>
                </div> 
                <div className="flex-container-center">
                    <DefaultButton title="Labels" disabled={false} onClick={updateChartLabels} primary={true} ></DefaultButton>
                </div>
            </div>
            <div className="chartGroup">
                <AYPALineChart
                    config={chartConfig}
                    data={lineData}
                    onCompleteCallback={() => onCompleteChartRender}
                    />
            </div>
            </>

        </Loading>
    )
}
